// PRINT PAGES STYLE
@import url('https://fonts.googleapis.com/css2?family=Cairo&family=Noto+Sans+Arabic:wght@500&display=swap');

.print-container {
    direction: rtl !important;
    width: 1000px !important;
    margin: auto !important;
    font-family: "Cairo", sans-serif !important;
    font-size: 16px !important;
    
    .card.card-custom {
        box-shadow: none;
    }
    .p-0 {
      padding: 0 !important ;
    }
    .table {
      width: 100% !important;
      margin-bottom: 1rem !important;
      color: #212529 !important;
      vertical-align: top !important;
      border-color: #dee2e6 !important;
      border-collapse: collapse !important ;
    }
    .table th,
    .table td {
      padding: 0.5rem !important;
      vertical-align: top !important;
    }
  
    .table thead th {
      vertical-align: bottom !important;
      border-bottom: 2px solid #dee2e6 !important;
    }
    .table-bordered {
      border: 1px solid #dee2e6 !important;
    }
    .table-bordered th,
    .table-bordered td {
      border: 1px solid #dee2e6 !important;
    }
    .table-bordered thead th,
    .table-bordered thead td {
      border-bottom-width: 2px !important;
    }
    .table-striped tbody tr:nth-of-type(odd) {
      background-color: rgba(0, 0, 0, 0.05) !important;
    }
    .text-primary {
      color: #65539a !important;
    }
    .bold {
      font-weight: 700 !important;
    }
    .text-secondary {
      color: #e88342 !important;
    }
    .text-left {
      text-align: left !important;
    }
    .text-right {
      text-align: right !important;
    }
    .text-center {
      text-align: center !important;
    }
    .page-header p {
      margin: 0 !important;
    }
    .client-details span:first-child {
      padding-left: 10px !important;
    }
    .client-details {
      border-bottom: 1px solid #ddd !important;
    }
    .invoice-details {
      margin: 30px 0 !important;
    }
    .client-details td{
      width :20%;
  }

  

  .goodStatus{
    background-color: green;
    color: white;
    padding: 10px;
    border-radius: 8px;
  }
  .poorStatus{
    background-color: red;
    color: white;
    padding: 10px;
    border-radius: 8px;
  }
}