.primary-secondary-button {
  min-width: 8rem;
  // height: 2.5rem;
  padding: 0.5rem 0.75rem;
  gap: 0.25rem;
  border-radius: 0.375rem;
  box-shadow: 0px 0px 4px 0px var(--color-light);
  color: #000;
  border: none;
}

.primary-button:not(:disabled) {
  @extend .primary-secondary-button;
  background: var(--color-theme-2);
  color: white;
}

.primary-button:disabled {
  @extend .primary-secondary-button;
  background: var(--grey-1);
  color: white;
}

.secondary-button {
  @extend .primary-secondary-button;
  // background: var(--blue-50);
  outline: none;
  color: black;
}

// Small Screens
@media (max-width: 576px) {
  .primary-button {
    // height: 2rem;
    width: 100% !important;
    // padding: 0 1rem;
  }
}