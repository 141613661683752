// bootstrap override input field
.form-control{
  // min-width: 350px !important;
  padding: 0.75rem !important;
}

.form-control:focus{
  border-color: var(--color-primary) !important;
  box-shadow: 0 0 0 .35rem rgb(0 0 0 / 0.06) !important;
}

// Change autofill background color
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-background-color: white;
    -webkit-background-clip: text;
    // -webkit-text-fill-color: #ffffff;
    // transition: background-color 5000s ease-in-out 0s;
    // box-shadow: inset 0 0 20px 20px #23232329;
}


// Small Screens
@media (max-width: 576px) {
  .form-control{
    min-width: unset !important;
  }
}