.custom-tables{
  .table{
    --bs-table-bg: transparent !important;
    border-collapse: separate;
    border-spacing:0 10px;
  }
  thead{
    background-color: transparent !important;
    border:  1px solid transparent !important;
    // text-align: center;
  }
  th{
    font-weight: 500 !important;
    letter-spacing: 0.025em !important;
    font-size: 0.875rem !important;
    color: #475569 !important;
    line-height: 1.25rem !important;
  }
  
  tbody > tr{
    border: 1px solid #e2e8f0 !important;
    background-color: white;
   
  }
  
  /* Rounded corners */
  .table tbody tr td:first-child {
    border-start-start-radius: 0.6rem; 
    border-end-start-radius: 0.6rem;
  }
  /* Rounded corners */
  .table tbody tr td:last-child {
    border-start-end-radius: 0.6rem; 
    border-end-end-radius: 0.6rem;
  }
  
  td{
    vertical-align: middle;
    // text-align: center;
    // padding: 0.75rem 1.25rem !important;
    padding: 0.75rem 0.5rem !important;
  }

  // Action columns style
  .table-actions-column{
    border-inline-start: 1px solid rgb(226 232 240);
    padding: 0.5rem 0;
    .action-button{
        margin-inline-start: 1rem;
    }
  }

}