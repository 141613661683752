// h2{
//   font-size: 1.875rem !important;
//   line-height: 2.25rem !important;
//   font-weight: inherit !important;
// }

.heading-text{
  font-size: 1.125rem;
  line-height: 1.75rem;
  color: var(--grey-1);
  font-weight: 500;
}

.text-xs {
  font-size: 0.75rem !important;
  line-height: 1rem !important;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-lg {
  font-size: 1.125rem !important;
  line-height: 1.75rem !important;
}

.text-3xl{
  font-size: 1.875rem !important;
}

.text-4xl{
  font-size: 2.25rem !important;
}

.leading-tight {
  line-height: 1.25;
}

.fw-100{
  font-weight: 100;
}
.fw-200{
  font-weight: 200;
}
.fw-300{
  font-weight: 300;
}
.fw-400{
  font-weight: 400;
}
.fw-500{
  font-weight: 500;
}
.fw-600{
  font-weight: 600;
}
.fw-700{
  font-weight: 700 !important;
}
.fw-800{
  font-weight: 800;
}
.fw-900{
  font-weight: 900;
}