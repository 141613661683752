
.-color-theme-1{
  color: var(---color-theme-1);
}
.color-theme-2{
  color: var(--color-theme-2);
}
.color-primary{
  color: var(--color-primary);
}
.color-secondary{
  color: var(--color-secondary);
}
.color-success{
  color: var(--color-success);
}
.color-info{
  color: var(--color-info);
}
.color-warning{
  color: var(--color-warning);
}
.color-pending{
  color: var(--color-pending);
}
.color-danger{
  color: var(--color-danger);
}
.color-light{
  color: var(--color-light);
}
.color-dark{
  color: var(--color-dark);
}
.grey-1{
  color: var(--grey-1);
}
.text-white{
  color: white;
}
.text-white-70{
  color: rgba(255, 255, 255, 0.70);
}

