/* You can add global styles to this file, and also import other style files */


// => Material
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import '@angular/material/prebuilt-themes/indigo-pink.css'; // or any other theme

@import "../src/assets/Styles/primary-secondary-button.scss";
@import "../src/assets/Styles/colors.scss";
@import "../src/assets/Styles/forms.scss";
@import "../src/assets/Styles/typography.scss";
@import "../src/assets/Styles/table.scss";
@import "../src/assets/Styles/material-forms.scss";
@import "../src/assets/Styles/print.scss";
@import "../src/assets/Styles/cards.scss";

// FontAwesome
@import "../node_modules/@fortawesome/fontawesome-free/css/all.css";
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Tajawal:wght@200;300;400;500;700;800;900&display=swap');
// $custom-typography: mat-typography-config($font-family: '"Tajawal", sans-serif;');
// @include mat.core($custom-typography);

*{
    padding: 0;
    box-sizing: border-box;
}

*:not(.fa):not(.fas):not(.far):not(.fal):not(.fab){
    font-family: "Roboto", sans-serif !important;
}
html, body { 
    height: 100%;
    --mat-form-field-container-text-size: 14px !important;
    --mat-select-trigger-text-size: 14px !important;
}

body { 
    margin: 0; 
    
}

html[dir='rtl']{
    *:not(.fa):not(.fas):not(.far):not(.fal):not(.fab){
        font-family: "Tajawal", sans-serif !important;
    }
    
}

:root{
    --color-theme-1: rgb(70,56,104); //#463868
    --color-theme-2: rgb(103,83,154); //#67539a
    --color-primary: rgb(103,83,154); //#67539a
    --color-secondary: rgb(233,131,65); //#e98341
    --color-success: rgb(132 204 22);  //#84cc16
    --color-info: rgb(6 182 212); //#06b6d4
    --color-warning: rgb(250 204 21); //#facc15
    --color-pending: rgb(249 115 22); //#f97316
    --color-danger: rgb(220 38 38); //#dc2626
    --color-light: rgb(241 245 249); //#f1f5f9
    --color-dark: rgb(30 41 59); //#1e293b
    --grey-1: #475569;
    --grey-2:#dee2e6;
    --grey-3:#b1b3b6;
    --grey-4:#e2e8f0;
}

.mat-mdc-progress-spinner {
    --mdc-circular-progress-active-indicator-color: white;
}

// Fix mat select options appear behind bootstrap NgbModal (dialog)
.cdk-overlay-container {
    z-index: 99999 !important;
 }
