/******* Full width fields *******/
.mat-mdc-form-field {
    width: 100% !important;
}
/***************************/

/******* field white background *******/
// For optional
.white-bg-field{
    .mat-mdc-text-field-wrapper{
        background-color: white !important;
        border-radius: 0.6rem;
    } 
}

// To apply for all
// .mat-mdc-text-field-wrapper{
//     background-color: white !important;
//     border-radius: 0.6rem;
// } 
/***************************/

/******* field border radius *******/
body[direction='ltr']{
    .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading {
        border-radius: 0.6rem 0 0 0.6rem !important;
    }
    .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__trailing {
        border-radius: 0 0.6rem 0.6rem 0 !important;
    }
}

body[direction='rtl']{
    .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading {
        border-radius:  0 0.6rem  0.6rem 0 !important;
    }
    .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__trailing {
        border-radius: 0.6rem 0  0 0.6rem !important;
    }
}
/***************************/


/******* field border color *******/
// Default
.mdc-text-field:not(.mdc-text-field--invalid):not(.mdc-text-field--focused):not(.mdc-text-field--disabled) {
    .mdc-notched-outline > * {
        border-color:var(--grey-2) !important;
    }
}

// Error
.mdc-text-field.mdc-text-field--invalid{
    .mdc-notched-outline > * {
        border-color:var(--color-danger) !important;
    }
}
// Focused
.mdc-text-field.mdc-text-field--focused{
    .mdc-notched-outline > * {
        border-color:var(--grey-2) !important;
    }
}
// disabled
.mdc-text-field.mdc-text-field--disabled{
    .mdc-notched-outline > * {
        border-color:var(--grey-2) !important;
    }
}

.read-only-field{
    --mdc-outlined-text-field-input-text-color:var(--grey-3) !important;
    .mat-mdc-text-field-wrapper{
        background-color: #FAFAFA !important;
    } 
}
/***************************/


/******* field label *******/
.mdc-floating-label{
    font-size: 1rem !important;
}

// Mat Froms label with prefix in rtl fix (overwrite with -1 instead of 1)
body[dir='rtl']{
    .mat-mdc-form-field-has-icon-prefix{
        .mdc-floating-label{
            transform: var(--mat-mdc-form-field-label-transform, translateY(-50%) translateX(calc(-1*(52px + var(--mat-mdc-form-field-label-offset-x))))) !important;
        }
    }
}

// Mat Forms prefix fix style
.mat-mdc-form-field-icon-prefix{
    .mat-icon{
        height: unset !important;
        font-size: unset !important;
    }
}
/***************************/

/******* Autocomplete/Select dropdown *******/ 
//dropdown list container
.mat-mdc-autocomplete-panel, .mdc-menu-surface.mat-mdc-select-panel{
    padding: 0.5rem !important;
    // hide divider between each item in list
    .mat-divider{
        display: none;
    }
}

.mat-mdc-option{
    display: flex !important;
    justify-content: center !important;
}
// dropdown item hover
.mat-mdc-option:hover, .mat-mdc-option.mdc-list-item--selected{
    background-color: var(--blue-50) !important;
    border-radius: 1rem;
}

// dropdown item style
.mat-mdc-option .mdc-list-item__primary-text{
    font-size: 0.875rem !important;
    color: var(--neutral-black) !important;
}

// dropdown active item
.mat-mdc-option-active{
    background-color: transparent !important;
}

/***************************/


// Large Screens
@media (min-width: 992px) {

}
// Medium Screens
@media (min-width: 576px) and (max-width: 992px) {

}
// Small Screens
@media (max-width: 576px) {

}
